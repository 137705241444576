import React, { useRef } from 'react'
import { Provider, connect } from 'react-redux'
import WebFont from 'webfontloader'

import {
  createMenuSelector,
  layoutModeSelector,
} from '~p/client/common/selectors'

import Layout from '~rawlings/client/components/Layout'
import TextAndFontInput from '~rawlings/client/components/TextAndFontInput'

import controlTree from '../controlTree'

import Renderer from '../containers/Renderer'
import Renderer3d from '../components/Renderer3d'
import getCameraFromMenu from '../../renderer/getCameraFromMenu'

import '~rawlings/client/components/Main.css'
import '~rawlings/client/components/Easton.css'

WebFont.load({
  google: {
    families: ['Open Sans:300,400,600,700'],
  },
})

const customComponents = {
  TextAndFontInput,
}

const getProductInfo = (nodes, recipeId) => ({
  summarySections: [],
  title: nodes['calc.skuLabel'].value,
  priceWithCurrency: nodes['calc.priceWithCurrency'].value,
  configurationId: recipeId,
})

const menuSelector = createMenuSelector(controlTree)

const mapStateToProps = (state) => ({
  menu: menuSelector(state),
  layoutMode: layoutModeSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  change: (path, value) => dispatch(controlTree.change(path, value)),
  commitChanges: () => dispatch(controlTree.commitChanges()),
})

const ConnectedLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const setCameraRef = useRef()

  // TODO: Enable 3D for easton-gloves based on vendor.features.
  // Try reusing some code from rawlings-gloves 3D rendering once it's ready.
  const is3d = false

  return (
    <Layout
      Renderer={
        is3d ?
          <Renderer3d
            onSetCamera={(setCamera) => {
              setCameraRef.current = setCamera
            }}
            setCameraRef={setCameraRef}
            defaultCamera={getCameraFromMenu(props.menu)}
          />
        : <Renderer layoutMode={props.layoutMode} />
      }
      getProductInfo={getProductInfo}
      customComponents={customComponents}
      onChangePost={(path) => {
        const LOGO_PATH = 'personalization.embroidery.logo'
        const NUMBER_PATH = 'personalization.embroidery.number.text'

        props.commitChanges()

        if (path === LOGO_PATH) {
          // Reset number when logo is set.
          props.change(NUMBER_PATH, '')
        } else if (path === NUMBER_PATH) {
          // Reset logo when number is set.
          props.change(LOGO_PATH, null)
        }
      }}
      onOpenMenuPost={(menu) => {
        const setCamera = setCameraRef.current

        if (!setCamera) {
          return
        }

        const camera = getCameraFromMenu(menu)

        if (!camera) {
          return
        }

        setCamera(camera)
      }}
      countDown={{
        message: 'Holiday Order Deadline',
        deadline: '2022-11-09 00:00-05:00',
      }}
      logoSrc="logo-easton.png"
      logoAlt="Easton® Custom Pro Shop"
      extraClasses="EastonLayout"
      isScrollMenuLayout={props.layoutMode !== 'mobile'}
    />
  )
})

const Root = ({ store }) => (
  <Provider store={store}>
    <ConnectedLayout />
  </Provider>
)

export default Root
